import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from "../../components/Layout";
import { SEO } from "gatsby-plugin-seo";
import { Section, ProfileImage, BookCtaBanner } from "../../components/UI";
import { MDXProvider } from "@mdx-js/react";
import { H1, Lead, MdxComponentMap } from "../../components/Typography";
import { useSiteMetadata } from "../../hooks";
export const _frontmatter = {
  "path": "/about",
  "title": "About",
  "subtitle": "Author of PERPETUAL, The Secret to Finding God in your 7 Life Seasons",
  "description": "About Howard Rachinski, author of PERPETUAL, The Secret to Finding God in your 7 Life Seasons"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  pageContext,
  children
}) => {
  const {
    siteUrl
  } = useSiteMetadata();
  return <Layout>
      <SEO schema={`{
          "@context": "http://schema.org",
          "@type": "WebPage",
          "mainEntity": {
            "@type": "Person",
            "name": "Howard Rachinski",
            "birthDate": "1951-04-26",
            "birthPlace": "Vancouver, Canada",
            "gender": "male",
            "url": "${siteUrl}/about",
            "image": "${siteUrl}/static/img/howard-rachinski.png"
          }
        }`} title={pageContext.frontmatter.title} description={pageContext.frontmatter.description} pagePath="/about" />
      <BookCtaBanner />
      <div className="max-w-2xl flex flex-col mx-auto mb-20 pt-10">
        <Section>
          <div className="flex items-center mb-5">
            <div>
              <h2 className="font-serif italic text-2xl tracking-wide text-blue4">
                All About
              </h2>
              <H1 className="leading-tight">Howard Rachinski</H1>
            </div>
          </div>
          <MDXProvider components={MdxComponentMap}>{children}</MDXProvider>
        </Section>
      </div>
    </Layout>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p className="italic mb-10">
  “A star is one who shines in his abilities - A hero is one who shines in his
  character. Worship is where we experience the shock of facing our own
  inabilities in the light of His Awesomeness. At that moment of intimate
  awareness, He adorns us with His Love, His Character. And, in His Presence, He
  loves making heroes."
    </p>
    <h2 {...{
      "id": "personal-background"
    }}>{`Personal Background`}</h2>
    <p><strong parentName="p">{`Howard Rachinski was born in Vancouver, Canada`}</strong>{` on April 26, 1951 to Ernie `}{`&`}{` Ida Rachinski. He graduated from Vancouver Technical Secondary School in 1969, and began to attend the University of British Columbia, where he intended to pursue a career as a Chartered Accountant. After his first semester at UBC, Howard felt the Lord call him to ministry, so he began to pursue his theology degree at Glad Tidings Bible College in Vancouver, BC.`}</p><p>{`Howard met Donna Kirkpatrick at their church and, in February 1973, they committed their life together in marriage.`}</p>
    <div {...{
      "className": "shadow w-full my-4"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ae8780c89587e379b029e4993d6734ff/940e1/wedding.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.74632007516442%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAGfqTUnWWPB/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIDEQAEEiH/2gAIAQEAAQUCX3AoMXQUgU0SA67R03//xAAXEQEAAwAAAAAAAAAAAAAAAAAAARFB/9oACAEDAQE/AcVL/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BR//EABoQAQEAAgMAAAAAAAAAAAAAAAEAAlERMZH/2gAIAQEABj8CbF2ycLNganry/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBYXH/2gAIAQEAAT8hoRomwJm1fI4AHoymL4qKHur9zqFbcf/aAAwDAQACAAMAAAAQ1N//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QTAxhf//EABURAQEAAAAAAAAAAAAAAAAAAAEA/9oACAECAQE/EFJZ/8QAHRABAQABBAMAAAAAAAAAAAAAAREAITFBkVFx8P/aAAgBAQABPxCKAzR4m3eHVGMF+7PeLGyKDFAmmjnH7ihBwVxq6kLq1fef/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Howard's wedding",
            "title": "Howard's wedding",
            "src": "/static/ae8780c89587e379b029e4993d6734ff/6c315/wedding.jpg",
            "srcSet": ["/static/ae8780c89587e379b029e4993d6734ff/f562b/wedding.jpg 320w", "/static/ae8780c89587e379b029e4993d6734ff/e2288/wedding.jpg 640w", "/static/ae8780c89587e379b029e4993d6734ff/6c315/wedding.jpg 1280w", "/static/ae8780c89587e379b029e4993d6734ff/fb329/wedding.jpg 1920w", "/static/ae8780c89587e379b029e4993d6734ff/2b872/wedding.jpg 2560w", "/static/ae8780c89587e379b029e4993d6734ff/940e1/wedding.jpg 3193w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <p>{`During this same time, Howard became a purchasing agent at an international mining corporation, and added 2 ½ years of Business College to his educational pursuits. Howard graduated from Glad Tidings Bible College with a Certificate of Theology in 1976 and, in 1977, Howard and Donna moved to Bellingham, WA where he became Associate Pastor of Glad Tidings Church. During the 6 ½ years that he was with the church, Howard also served as Dean of Ecclesia Bible College and began to lead the church’s worship/music activities.`}</p>
    <p>{`To alleviate some of the church’s financial pressure of the early 1980s, Howard became an insurance broker and won several awards, including “1982 Rookie of the Year” and “Top Insurance Agent”.`}</p>
    <p><strong parentName="p">{`In 1983, Howard and the family moved to Portland, OR`}</strong>{` and began to attend Bible Temple, under the direction of Pastor Dick Iverson. In early 1984, Pastor Iverson asked Howard to become the church’s Music Minister. For the next 4 ½ years, Howard led an 80-voice choir and 30-piece orchestra, wrote songs and drama presentations for Christmas and Easter productions, produced worship and choir recordings, and hosted an annual Northwest Worship Conference.`}</p>
    <p>{`Howard and Donna currently live in Vancouver, WA (just across the river from Portland, OR). In February 2019, Howard and Donna celebrated their 46th Anniversary. They have 3 children – Dyane (married to Bryan Davis), Deryk (married to Kristen) and Dryden. They also “currently” have 7 grandchildren – Judah, Delaney, Carter, Sawyer, Stella, Sydney, and Elyza.`}</p>
    <div {...{
      "className": "shadow w-full mb-24"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/495a7d652e455f73e28ed9b5d584844b/243ce/family.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABqXCsdgzCz//EABoQAAIDAQEAAAAAAAAAAAAAAAABAhESAxP/2gAIAQEAAQUC94xb6aawy8kZNis//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BiP/EABcRAQADAAAAAAAAAAAAAAAAAAAREmH/2gAIAQIBAT8BlbH/xAAcEAACAgIDAAAAAAAAAAAAAAAAEQEhEjEyUaH/2gAIAQEABj8Cx2OGujjBNFNs16f/xAAbEAADAQADAQAAAAAAAAAAAAAAAREhMUFRYf/aAAgBAQABPyFoqSLCzoJJwTWBzITi+jxvReeGO3Ox/9oADAMBAAIAAwAAABA77//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8Qmwez/8QAFREBAQAAAAAAAAAAAAAAAAAAEDH/2gAIAQIBAT8Qgv/EAB0QAQACAgMBAQAAAAAAAAAAAAEAESExYYGRQaH/2gAIAQEAAT8QBvcVm/MEULcDbLp4OZYK83TRMjkUDVHfk1WoKAfG+/YsCPDAX+T/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Howard Rachinski and his family",
            "title": "Howard Rachinski and his family",
            "src": "/static/495a7d652e455f73e28ed9b5d584844b/6c315/family.jpg",
            "srcSet": ["/static/495a7d652e455f73e28ed9b5d584844b/f562b/family.jpg 320w", "/static/495a7d652e455f73e28ed9b5d584844b/e2288/family.jpg 640w", "/static/495a7d652e455f73e28ed9b5d584844b/6c315/family.jpg 1280w", "/static/495a7d652e455f73e28ed9b5d584844b/fb329/family.jpg 1920w", "/static/495a7d652e455f73e28ed9b5d584844b/2b872/family.jpg 2560w", "/static/495a7d652e455f73e28ed9b5d584844b/243ce/family.jpg 4032w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <h2 {...{
      "id": "ccli-experience"
    }}>{`CCLI Experience`}</h2>
    <p><strong parentName="p">{`In 1984`}</strong>{`, a local Portland newspaper printed an article about a major lawsuit - $3.1 million – that had been filed against the Chicago Archdiocese, with a “settlement” of $190,400 being awarded. This lawsuit came to Pastor Iverson’s attention and, understandably, the church was concerned about their own music copying activities.`}</p>
    <p>{`In December 1984, Howard told his pastor he would begin exploring some thoughts on how to address this issue. Howard began to study the US Copyright Act of 1976, and began to contact publishers to see how much it would cost to get “permission” to copy their songs. Some publishers said “free”, some said “$15” and others said “$50”. At that time, Bible Temple had 400 song transparencies and, with the average permission cost of $15 per song, it would have cost the church $6,000 just to make legal their current transparencies, let alone not even address all the new songs being introduced to the church.`}</p>
    <p>{`As a result of this research, Howard decided to develop an annual license, whereby churches could get legal permission to copy songs for an affordable fee. To address this service, Howard launched a ministry called “StarPraise Ministries”, as part of Bible Temple’s Music Ministry, and drafted the “StarPraise Permission of Use Agreement” that would allow churches and publishers to work together in affordable compliance with the copyright law.`}</p>
    <p>{`In May 1985, at a Northwest Ministers Conference, StarPraise Ministries was introduced to approximately 300 churches, for an annual fee of \$75. 25 churches signed up immediately at this conference, and StarPraise Ministries was born. Throughout that summer, StarPraise Ministries began to compile a songbook of 275 songs that were covered by this annual license service.`}</p>
    <div {...{
      "className": "shadow w-full my-4"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "728px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7cd42f642659fc7961a9b11d522a8923/e2ef7/starpraise-ministries.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04945054945055%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABA//EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAEDhYaPuNNf/8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIAAxEEEiH/2gAIAQEAAQUCOluOYW97lbC+yttX/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAEDAQE/ATWL/8QAFREBAQAAAAAAAAAAAAAAAAAAEEH/2gAIAQIBAT8Bh//EABoQAAIDAQEAAAAAAAAAAAAAAAABAhExEFH/2gAIAQEABj8CpEoy9HhaMXP/xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhMWFx/9oACAEBAAE/IXXnwSkxwmZTjNUSWg7XoOtP/9oADAMBAAIAAwAAABDzz//EABgRAAIDAAAAAAAAAAAAAAAAAAABESFx/9oACAEDAQE/EFck0f/EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QQcShzL//xAAdEAEAAgICAwAAAAAAAAAAAAABESEAMUGRUWFx/9oACAEBAAE/EJeDAQ55jDKCCrL5+6M13mstdYP+mvTG8DuMzRme8liaYIUrP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Starpraise Ministries at a Conference",
            "title": "Starpraise Ministries at a Conference",
            "src": "/static/7cd42f642659fc7961a9b11d522a8923/e2ef7/starpraise-ministries.jpg",
            "srcSet": ["/static/7cd42f642659fc7961a9b11d522a8923/f562b/starpraise-ministries.jpg 320w", "/static/7cd42f642659fc7961a9b11d522a8923/e2288/starpraise-ministries.jpg 640w", "/static/7cd42f642659fc7961a9b11d522a8923/e2ef7/starpraise-ministries.jpg 728w"],
            "sizes": "(max-width: 728px) 100vw, 728px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <p>{`In September 1985, StarPraise Ministries went “live” to 54 churches who had now bought this annual license, each receiving the StarPraise songbook. Although several major music publishers decided to “wait and see” how StarPraise progressed, two companies – Integrity Music and Tempo Music – agreed to participate in this new license service.`}</p>
    <p>{`After just one year of operation, StarPraise Ministries had grown to 250 churches. By the end of the 2nd year (September 1986), over 1,100 churches were now part of this annual license service. Howard and Bible Temple both realized that StarPraise Ministries was now much broader than the confines of just one church’s music department. After much discussion, it was decided that this “need-whose-time-had-come” should become its own identity, so that it could serve churches of all denominations.`}</p>
    <p>{`In September 1987, Howard made a trip to the UK to meet with Geoff Shearn, who had also launched a similar license service (Christian Music Association) to churches in the UK.`}</p>
    <p>{`In January 1988, Howard registered StarPraise Ministries as a company, and in April 1988, changed the name of the company to Christian Copyright Licensing International, Inc. (CCLI). Also, in April 1988, Geoff Shearn made a trip to the USA and spoke about his UK licensing service at the Church Music Publishers Association (CMPA) Annual Conference. As a result, the CMPA appointed an Ad-Hoc committee (Debra Mayes – Gaither Music Company, John Barker – The Benson Company, Ken Bible – Lillenas Music Company, Fred Bock – Fred Bock Music) to meet with Howard and discuss the possibility of endorsing CCLI for providing this license service to churches.`}</p>
    <p>{`Howard began to develop the business aspects of CCLI along with his close friend and partner, Victor Anfuso. In the summer of 1988, Howard, Victor and their publisher consultant, Phil Perkins, met with the CMPA’s Ad-Hoc committee many times to discuss Howard’s proposal for CCLI. As a result of those meetings, in August 1988, the CMPA endorsed CCLI to its publisher members.`}</p>
    <p>{`New contracts were drafted, discussed and approved by the publishers, and CCLI was officially launched in the US in October 1988, providing the Church Copyright License (copyright permission from 120 publishers) to its 1,600 churches who were already using their license service.`}</p>
    <p>{`After one year, CCLI had almost 10,000 churches and, by October 1990, CCLI had grown to over 21,000 churches. In 1990, CCLI expanded its license service to Canada and in July 1991, CCLI took over the operation of Geoff Shearn’s CMA license service in the UK, and merged it into CCLI’s international expansion.`}</p>
    <p>{`In April 1993, CCLI started its license service to churches in Australia and New Zealand, and, in June 1995, CCLI expanded to South Africa.`}</p>
    <p>{`In September 1997, CCLI began to offer, for the first time, a license in Australia and New Zealand, whereby churches could photocopy songbooks legally. This new licensing model now enabled publication owners, along with song owners, to receive royalties for the copying of songs in songbooks.`}</p>
    <p>{`In February 1998, CCLI expanded, for the first time, into non-English speaking countries with the launching of their license service to churches in the Nordic Countries – Denmark, Finland, Iceland, Norway and Sweden.`}</p>
    <p>{`In April 1998, CCLI launched the Music Reproduction License in the UK, providing legal permission for churches to photocopy songs from songbooks, similar to that in Australia and New Zealand.`}</p>
    <p>{`In 2005, CCLI expanded its license service to Germany and in 2009, CCLI launched its new South American service to churches in Brazil. And, in 2012, CCLI’s international expansion continued with the launching of its service to churches in South Korea.`}</p>
    <div {...{
      "className": "shadow w-full my-4"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1c9e35682efd95d0867062ef8cd7709b/0afc9/ccli-building.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.882716049382715%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAGWJ1ZNRYk//8QAGxAAAgIDAQAAAAAAAAAAAAAAAAIREwEDEiL/2gAIAQEAAQUCtTJKT4F1KVrHJ//EABcRAAMBAAAAAAAAAAAAAAAAAAABEwL/2gAIAQMBAT8BmieT/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAETAv/aAAgBAgEBPwGzL6P/xAAYEAADAQEAAAAAAAAAAAAAAAAAEBExQf/aAAgBAQAGPwLpKasX/8QAGhAAAwEAAwAAAAAAAAAAAAAAAAEhYRExQf/aAAgBAQABPyFLRI9RywjroaaPxYNNP//aAAwDAQACAAMAAAAQ+z//xAAXEQADAQAAAAAAAAAAAAAAAAAAATFh/9oACAEDAQE/EEymJ//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIWH/2gAIAQIBAT8Qd0u9/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIZExcf/aAAgBAQABPxD4QFR0uZq55CaPUjksVttngAjGtg2hj//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "CCLI building in Portland, Oregon",
            "title": "CCLI building in Portland, Oregon",
            "src": "/static/1c9e35682efd95d0867062ef8cd7709b/6c315/ccli-building.jpg",
            "srcSet": ["/static/1c9e35682efd95d0867062ef8cd7709b/f562b/ccli-building.jpg 320w", "/static/1c9e35682efd95d0867062ef8cd7709b/e2288/ccli-building.jpg 640w", "/static/1c9e35682efd95d0867062ef8cd7709b/6c315/ccli-building.jpg 1280w", "/static/1c9e35682efd95d0867062ef8cd7709b/fb329/ccli-building.jpg 1920w", "/static/1c9e35682efd95d0867062ef8cd7709b/2b872/ccli-building.jpg 2560w", "/static/1c9e35682efd95d0867062ef8cd7709b/0afc9/ccli-building.jpg 3888w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <p>{`In 2014, CCLI entered into new agreements with song owners, thereby enabling CCLI to provide its license service to churches in every country.`}</p>
    <p>{`Currently, CCLI now provides its license service to 250,000 churches in 70 countries.`}</p>
    <p>{`All of this international expansion was under the successful leadership of Howard Rachinski.`}</p>
    <p>{`Along with the Church Copyright License, Howard’s creative passion enabled CCLI to become an industry leader in digital song resources.`}</p>
    <p>{`In 1993, CCLI launched SongSelect, providing “floppy disks” of 6,000 songs with lyrics, and a worship planning software that could be used by the music ministry of churches. This new service enabled CCLI to enhance the value of songs through the distribution of royalties for “digital” activities.`}</p>
    <p>{`In 2002, Howard’s oversight enabled the successful conversion of SongSelect to an online digital download service. SongSelect could now provide several types of subscription services to churches that enabled them to download lyrics, chord sheets, lead sheets and vocal sheets, and to listen to music samples. In 2015, over 92,000 churches in 26 countries accessed and downloaded over 22 million digital song files, and SongSelect had almost 112 million page views from 233 countries.`}</p>
    <p>{`In 2005, Howard oversaw the development and launch of another creative resource – CCLITV – whereby churches could upload their original songs for others to see, review new songs from song owners and to receive practical instruction from artists.`}</p>
    <p>{`In 2011, Howard continued his creative oversight of CCLI with the development and successful launching of two new services to churches in the US. First, the Church Rehearsal License now gave churches the right to copying recorded product.`}</p>
    <p>{`This innovative license service established a new precedent in the Christian Music industry as now, recording owners, along with song owners, would receive royalties for the “second generation” copying of recording masters. Second, the Church Streaming License grants churches the right to stream their church services over the internet.`}</p>
    <p>{`After only a few years, over 14,000 churches were using the Church Rehearsal License and over 12,000 churches were using the Church Streaming License.`}</p>
    <p>{`Under Howard’s successful leadership, CCLI has distributed over \$ ½ Billion in royalties to Christian song owners over the past 28 years.`}</p>
    <p>{`At CCLI, Howard has served as:`}</p>
    <ul>
      <li parentName="ul">{`President/CEO from 1988 – 2012`}</li>
      <li parentName="ul">{`Chairman/CEO from 2012 – 2016`}</li>
      <li parentName="ul">{`Chairman/Founder from 2016 – 2018`}</li>
    </ul>
    <h2 {...{
      "id": "ministry-experience"
    }}>{`Ministry Experience`}</h2>
    <p>{`Howard was an Associate Pastor at Glad Tidings Church, Bellingham, WA for 6 ½ years, from 1977 - 1983. He was also Dean of Ecclesia Bible College for 3 years, from 1978 – 1981. Howard then served as Music Minister at Bible Temple, Portland, OR for 4 ½ years, from 1984 – 1988. In April 1988, Howard became an Elder at Bible Temple (now Mannahouse), and has served in that capacity for over 30 years. Howard became a member of the church’s Executive Leadership Team in 1996, and has served in that capacity for over 20 years.`}</p>
    <p>{`Howard is also recognized as an enthusiastic speaker at both churches, worship conferences and leadership conferences. He specializes in teaching and consulting on church music culture, music ministry and leadership development.`}</p>
    <h2 {...{
      "id": "industry-experience"
    }}>{`Industry Experience`}</h2>
    <p>{`Howard Rachinski has made a significant contribution to the Christian Music industry. Howard is recognized as a creative, strategic visionary by his industry peers. He is highly regarded as one of the most trusted leaders in the Christian Music industry, and is readily received as an experienced statesman by all who know him. His commitment to excellence, and his passion to serve, has enabled Howard to successfully facilitate much-needed mutual collaboration amongst many music companies.`}</p>
    <p>{`Over the past three decades, the music industry has experienced volatile paradigm shifts. Through Howard’s efforts, many music companies have been able to survive such volatility, and to even thrive in the licensing and technology directives that continuously evolve.`}</p>
    <h2 {...{
      "id": "accomplishments"
    }}>{`Accomplishments`}</h2>
    <div {...{
      "className": "shadow my-4"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/be1ab33d03fee995a57623827759f251/a1900/hall-of-fame-collage-v2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "97.71739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAFK0lEQVQ4yy2UaWxUVRTHX6e2TDv7+t7sr+20tEMZWmildAZpKS2FgULLIuLCYguILIJQqgKyFERAqFWkLUXAVPxgkIClAipBE5cQlURNTMQQQsI3EqMfTNQPP8+8+JKT3Lzc+7v/c87/XMXhGo/dUUJhoc6b/e9x67tf+ePPv8h+o6OfoSgFDA5+wMGDRwkFy7l79x73bn/L/Tt36Gh4jO0bNxt77z/4h59/+RvFYovjcMZxuhMsaZzF6fVP8dv3PxqbLlwYM4Bvnxhh756DTEym+Ob6RUZWtHB9aIDR7U8xvOU59vSeYGz0Jn//C0qhpQi7XcftS3D4+S6+3raIX69cNICXL10VoJmTJ0fo3X+IsrIpbFy3ie55Ddz+8isOrF3NhoWzCUfi7H51gGs3f0fJfSSI11tB6YQ5dD93gH1L5/JS127OX7jF0TfOCLCQw0eGOfTaMfw+ncnVMwRcRceC5aSmz2JKTR02i430jBX07P9B9itOxidaSNZ0oPtKeGHZs2RSi/D5qimvmImS46R75xB79/cTDBTR8FgriYo6UdnEsnQNfjVGUA1RlZzOyq5+FHNhBC1QgeqPEItX0NW0gI7aZtTwBMLhSqyOIO3Le9n84mEqxldSMzlFaXEVjckqljVMQ1WjaL4QiUQ9DU0rUbxqOeFoUhTFiMbKRcEs6qekcHtLCUpXA6EyYrFSlixeLrB6qmvSJPVyGqc24atM4bS7sVntVE6cTWfnMRS/Via3lKCpOh6BPpnJMPByNxZLBJcrRMOcTp7eMsjOPUfJtLSyZvkq1rU/weaeozy+6gWWtmSY19xKu6x79vShRPQEXgFlD3v8MdrT9axvbsTljuNxBmjb0E//d7B15xEpgca1rn2Mtm3i9VQ7I7NWcHXxdlrTzUx6tI6aunqU+PgklZOmEYpMwCUAn1pEntmD0xVD00qZ3zlI9/GHrFqzCyXfxLbaDGPTVrJVreKTmesYSj8j5QngVcOowRhKYmIt1VPSlJQkRUGcWLTMCE3TxQ4eMu1XpCE/0Ll2O76QTlCiu7KJM4kMX6Y6eaV6LhZ/QM4WE4qWoEyqrqN26nR0ST0iBg0GSwgEJLQoVXUL2XziJ3aceEjnhl78gTBBvQyzw0OHdHpXbSvJUByvFiYg6oKRYpRH66YzLdVINCodDehi8ogB9DhVpjavZtdHDzh4/Q/WvNiL1x/Cp8Woqq5lx84eDvcdMermcPoMoBaMoni8QbFM2ACpYlK7XcPtDspUxPC6o0T0iSTr5rBEuuvyhkinZ3Du7LucPT3ExQsf8vmNT5nZ1CIPjFcsFstOiols2Gw+MXdMXh0vDocma538fFd2A/m5eWTmt+MWhT09PQwPDzEoj8OHAvx47Ap9b/WLSi9qIIJiyrWRk2M2lGW7bDa7jbXHHRagm5xcq1zmYV7bIqOT+w/0Mnz6FMf7+hg5/z5j164xeOqUjGAYv9RSMZksAizAJhCHXSUvz0ZBgRunjFzuI3bjtbFanLQtXIzDrbHy2U4uXf6IUwJ9Z2CAG198wfqNm7DYXJKy1DALyxFoocWH1erDZCqQtN0C12RdKMBcmRon8xYsQg0VEQgXsWnLVs6cOysKz7N1W7f4Lyr+DaEZwGzKJivjxrkYJ+maTGYDaLOqZNVn62v9HxgQn2XN6/RoFJdWEC9LiGq/TFjQgEWKSgUoKsySotdbLCOoy8iFpcsSnhh5+XajKRaLnbb2JYSLygjr4tVosVFPj6gKhHU0iYD8ywL/A3SnxvBA2IrHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Howard speaking at the Gospel Music Hall of Fame Ceremony",
            "title": "Howard speaking at the Gospel Music Hall of Fame Ceremony",
            "src": "/static/be1ab33d03fee995a57623827759f251/a1900/hall-of-fame-collage-v2.png",
            "srcSet": ["/static/be1ab33d03fee995a57623827759f251/01387/hall-of-fame-collage-v2.png 320w", "/static/be1ab33d03fee995a57623827759f251/e49a9/hall-of-fame-collage-v2.png 640w", "/static/be1ab33d03fee995a57623827759f251/a1900/hall-of-fame-collage-v2.png 920w"],
            "sizes": "(max-width: 920px) 100vw, 920px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <p>{`Howard has considerable experience in the music industry:`}</p>
    <ul>
      <li parentName="ul">{`Founder of CCLI and creator of the Church Copyright License, a service currently used by approximately 250,000 churches worldwide`}</li>
      <li parentName="ul">{`Author of “The Church & Music Copyright Law” seminar`}</li>
      <li parentName="ul">{`Author of “PERPETUAL - The Secret to Finding God in Your 7 Life Seasons”`}</li>
      <li parentName="ul">{`Contributing editor to Worship Leader Magazine`}</li>
      <li parentName="ul">{`Recipient of the 1993 Paradigm Pioneer Award from the Administrators of Gospel Music`}</li>
      <li parentName="ul">{`Recipient (CCLI) of the 1994 Gospel Music Association’s Impact Award, for outstanding contribution to the Gospel Music Industry`}</li>
      <li parentName="ul">{`Recipient of Outstanding Achievement award in 1995 from the Administrators of Gospel Music`}</li>
      <li parentName="ul">{`Recipient of Exemplary Service award in 1999 from the Church Music Publishers Association`}</li>
      <li parentName="ul">{`Included in “Who’s Who In The World” and “Who’s Who In American Christian Leadership” editions`}</li>
      <li parentName="ul">{`Inducted into the GMA Gospel Music Hall of Fame in 2016`}</li>
    </ul>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      